// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-de-js": () => import("/Users/wigginus/Development/src/bitbucket.org/wigginus/clemenswilding.ch/src/pages/de.js" /* webpackChunkName: "component---src-pages-de-js" */),
  "component---src-pages-en-js": () => import("/Users/wigginus/Development/src/bitbucket.org/wigginus/clemenswilding.ch/src/pages/en.js" /* webpackChunkName: "component---src-pages-en-js" */),
  "component---src-pages-index-js": () => import("/Users/wigginus/Development/src/bitbucket.org/wigginus/clemenswilding.ch/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

exports.data = () => import("/Users/wigginus/Development/src/bitbucket.org/wigginus/clemenswilding.ch/.cache/data.json")

